<template>
  <div class="content">
    <div class="title">{{hTitle}}</div>
    <div class="descrip">
      <img class="left" :src="require('@/assets/goldmatrix/left-title-imge.png')"></img>
      <div>{{hDescrip}}</div>
      <img class="right" :src="require('@/assets/goldmatrix/right-title-imge.png')"></img>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    hTitle: {
      type: String,
      default: ''
    },
    hDescrip: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="scss">
.content {
  text-align: center;
  margin: 40px auto;
}
.title {
  font-size: 24px;
  font-family: Noto Sans S Chinese-Bold, Noto Sans S Chinese;
  font-weight: bold;
  color: #333333;
}
.descrip {
  height: 20px;
  font-size: 14px;
  font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
  font-weight: normal;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    margin: 7px;
    user-select: none;
  }
}
</style>
